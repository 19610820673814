<template>
    <div class="user_information">
        <span><label>{{$t('User')}}:</label> {{user}} <label>(</label> <a class="href" v-on:click="logout()" href="#">{{$t('logout')}}</a> <label>)</label></span>
        <div class="container">
            <div class="row">
                <div style="padding: 0" class="col-10">
                    <span><label>{{$t('Company')}}:</label> {{company}} </span>
                </div>
                <div style="padding: 0; text-align: right" class="col-2">
                    <span style="display: flex;justify-content: flex-end; padding-right: 5px;"><font-awesome-icon
                            size="lg" icon="clipboard-list"/> <span style="padding-left: 5px; font-size: 13px;">(<a id="miniScanlist"
                                                                                                   >{{emptyScans}}</a>)</span></span>
                </div>
            </div>
        </div>
        <v-dialog width="80%"/>
    </div>
</template>

<script>
    import { EventBus } from '../event-bus.js'

    export default {
        name: 'UserInfo',
        data() {
            return {
                user: '',
                company: '',
                emptyScans: 0,
            }
        },
        mounted() {
            this.showInformations();
            this.initEventListeners();
            this.initMiniScanList();
        },
        methods: {
            initMiniScanList() {
                let itemList = JSON.parse(localStorage.getItem('item_list'));
                if(itemList !== null){
                    this.emptyScans = itemList.length
                }
            },
            initEventListeners(){
                let ctrl = this;
                document.querySelector('#miniScanlist').addEventListener('click', function() {
                    if(ctrl.$router.currentRoute.name !== 'list'){
                        ctrl.$router.push('list')
                    }
                });
                EventBus.$on('scanCountChanged', scanCount => {
                    this.emptyScans = scanCount;
                });
            },
            showInformations() {
                let company = localStorage.getItem('proline_company')
                if (company) {
                    let company = JSON.parse(localStorage.getItem('proline_company'));
                    if (company[0].name !== undefined) {
                        this.company += company[0].name;
                    }
                    if (company[0].customer_id !== undefined) {
                        this.company += " (" + company[0].customer_id + ")";
                    }
                }
                if (localStorage.getItem('login_user')) {
                    this.user = localStorage.getItem('login_user');
                }
            },
            logout() {
                let itemList = JSON.parse(localStorage.getItem('item_list'));
                if (itemList !== null && itemList.length > 0) {
                    this.$modal.show('dialog', {
                        text: "<div class='clearitems-modal'>" +
                            "<span>" + this.$t('There are still scanned data left. Should they be deleted?') + "</span>" +
                            "</div>",
                        buttons: [{
                            title: this.$t('Yes'),
                            handler: () => {
                                this.$modal.hide('dialog');
                                this.clearStorage();
                            }
                        },
                            {
                                title: this.$t('Cancle'),
                                handler: () => {
                                    this.$modal.hide('dialog');
                                }
                            }]
                    })
                } else {
                    this.clearStorage();
                }

            },
            clearStorage() {
                localStorage.clear();
                if(this.$router.currentRoute.path !== '/'){
                    this.$router.push({ path: '/' })
                }
                location.reload();
            }
        }
    }
</script>
<style scoped>
    #miniScanlist{
        text-decoration: underline;
        color: #156A9E;
    }
</style>