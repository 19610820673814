<template>
    <div id="home">
        <div class="container">
            <div class="home col">
                <div class="row">
                    <div class="col-12 salutation">
                        <a v-if="customer !== null ">{{ $t('Hello') }} {{customer.firstname}} {{customer.lastname}}</a>
                    </div>
                </div>
            </div>
            <div class="home col">
                <div class="row">
                    <div class="col-6 scantime-title">
                        <a>{{$t('Date of the last scan transmission')}}: </a>
                    </div>
                    <div class="col-6 scantime-time">
                        <a>{{lastScan}}</a>
                    </div>
                </div>
            </div>
            <div class="home col">
                <div class="row">
                    <div class="col-3" style="display: flex; flex-direction: column; text-align: -webkit-center">
                        <button class="contact-button" v-on:click="callContact()">
                            <font-awesome-icon icon="phone" size="lg"/>
                        </button>
                        <button class="contact-button" v-on:click="emailContact()">
                            <font-awesome-icon icon="envelope" size="lg"/>
                        </button>
                    </div>
                    <div class="col-9 contact">
                        <div class="company-contact">
                            <span><b>Voss {{$t('Contact Person')}}:</b></span><br>
                            <span>{{contact.firstname}} {{contact.lastname}}</span><br>
                            <span>{{contact.telephone}}</span><br>
                            <span>{{contact.email}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="home col">
                <div class="row">
                    <div class="col-12 menu">
                        <button v-on:click="goToMenu()">
                            <font-awesome-icon icon="align-justify" size="lg"/>
                            {{$t('Menu')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Trans} from '@/plugins/Translation'
    import auth from '../mage2rest.js';

    export default {
        name: 'home',
        data() {
            return {
                user: [],
                contact: [],
                customer: [],
                lastScan: '',
            }
        },
        methods: {
            goToMenu() {
                this.$router.push('menu');
            },
            callContact() {
                document.location.href = 'tel:' + this.contact.telephone
            },
            emailContact() {
                location.href = "mailto:" + this.contact.email;
            },
            initLocale(){
                let lang = localStorage.getItem('locale');
                if(lang !== null){
                    Trans.changeLanguage(localStorage.getItem('locale'));
                }
            },
            initUserInformations(){
                let contact = JSON.parse(localStorage.getItem('proline_company_contact'));
                if(contact !== null){
                    this.contact = contact[0];
                }
                this.customer = JSON.parse(localStorage.getItem('mage_customer_infos'));
                let ctrl = this;
                if(this.customer !== null){
                    if (this.customer.custom_attributes.length > 0) {
                        this.customer.custom_attributes.forEach(function (e) {
                            if (e.attribute_code.valueOf() === "voss_last_scan") {
                                let a = new Date(e.value * 1000);
                                ctrl.lastScan = ("00" + a.getDate()).slice(-2) + '.'
                                    + ("00" + (a.getMonth() + 1)).slice(-2) +
                                    '.' + a.getFullYear() + ' '
                                    + ("00" + a.getHours()).slice(-2) + ':'
                                    + ("00" + a.getMinutes()).slice(-2);
                            }
                        })
                    }
                }
            }
        },
        mounted() {
            if(!auth.isAuthenticated()){
                document.querySelector('#home').style.display = 'none';
            }else {
                this.initLocale()
                this.initUserInformations()
            }
        }
    }
</script>

<style scoped>
    .scantime-title {
        text-align: left;
        font-size: 14px;
    }

    .scantime-time {
        text-align: right;
        font-size: 14px;
        padding-top: 20px;
    }

    .salutation {
        margin-top: 10px;
        font-size: 18px;
        color: black;
        font-weight: bold;
        text-align: -webkit-left;
    }
</style>

