<template>
    <div id="voss-footer" class="card-header">
        <div class="container">
            <div class="row" style="margin-top: 0;">
                <div class="policy"><a v-on:click="getPrivacyPolicy">{{ $t('Privacy Policy') }}</a></div>
                <div class="imprint"><a v-on:click="getImprint">{{ $t('Imprint') }}</a></div>
            </div>
        </div>
        <v-dialog :adaptive="true" width="80%"/>
    </div>
</template>
<script>
    import config from '../config.js'

    export default {
        name: 'vossFooter',
        methods: {
            getPrivacyPolicy() {
                window.open(config.links.store.privacypolicy);
            },
            getImprint() {
                window.open(config.links.store.imprint);
            },
        }
    }
</script>