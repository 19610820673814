<template>
  <select
    class="LanguageSwitcher"
    name="language"
    @change="changeLanguage"
  >
    <option
      v-for="lang in supportedLanguages"
      :key="lang"
      :selected="isCurrentLanguage(lang)"
      :class="{ 'is-selected': isCurrentLanguage(lang) }"
      :value="lang"
    >
      {{lang}}
    </option>
  </select>
</template>
<script>
import { Trans } from '@/plugins/Translation'

export default {
  computed: {
    supportedLanguages () {
      return ['DE', 'EN'];
    },
    currentLanguage () {
      return Trans.currentLanguage
    }
  },
  methods: {
    changeLanguage (e) {
      const lang = e.target.value
      localStorage.setItem('locale', lang)
      return Trans.changeLanguage(lang)
    },
    isCurrentLanguage (lang) {
      return lang === this.currentLanguage
    }
  },
  mounted() {
    localStorage.setItem('locale', Trans.defaultLanguage)
  }
}
</script>
