<template>
  <div  class="login">
    <div>
      <div class="card-header" style="font-weight: bold">Scanner Login</div>
      <div class="card-body">
        <div class="container" style="border: 1px solid #e5e5e5; border-radius: 15px;">
          <div class="row">
            <div class="col-12">
              <input v-bind:placeholder="transMailPlaceholder" type="text" id="email_address" class="form-control" name="email-address" v-model="user" autofocus required>
            </div>
            <div class="col-12">
              <input v-bind:placeholder="transPassPlaceholder" type="password" id="password" class="form-control" name="password" v-model="pass" required>
            </div>
            <div class="col-12">
              <button v-on:click="login()" class="btn btn-primary">Login</button>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  </div>
</template>

<script>
  import auth from '../mage2rest.js';
  import Footer from '@/components/Footer'

  export default {
    data() {
      return {
        user: '',
        pass: '',
        fullPage: true,
        transMailPlaceholder: this.$t('Email-Address'),
        transPassPlaceholder: this.$t('Password'),
      }
    },
    components: {
      Footer
    },
    name: 'login',
    methods: {
      async login(){
        let ctrl = this;
        if(this.pass !== '' && this.user !== ''){
          let loader = this.$loading.show({
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: false,
            width: 45,
            height: 45,
            loader: 'bars'
          });
          let success = await auth.doLogin(this.user, this.pass);
          if(success){
            loader.hide();
            window.location.reload()
          }else {
            loader.hide();
            ctrl.showModal(this.$t('Account sign-in was incorrect or your account is temporarily disabled.'))
          }
        }
      },
      showModal(msg) {
        this.$modal.show('dialog', {
          text: '<div style="text-align: center">'+msg+'</div>',
          buttons: [
            {
              title: 'Close'
            },
          ]
        })
      }
    },
    mounted() {
      let ctrl = this;
      document.querySelector('.LanguageSwitcher').addEventListener("change", function(){
        ctrl.transMailPlaceholder = ctrl.$t('Email-Address')
        ctrl.transPassPlaceholder = ctrl.$t('Password')
      });

    }
  }
</script>

