<template>
  <div id="app">
    <div id="header">
      <div class="container">
        <div class="navbar">
          <div class="" id="store-logo">
            <a><img alt="Store logo" src="./assets/img/voss-logo.png"></a>
          </div>

          <LanguageSwitcher v-if="!isAuthenticated"/>
          <BackButton/>
          <UserInformation v-if="isAuthenticated"/>
        </div>
      </div>
    </div>
    <div id="content">
      <Login v-if="!isAuthenticated"/>
      <router-view v-if="isAuthenticated"/>
    </div>
  </div>
</template>

<script>
  import LanguageSwitcher from '@/components/LanguageSwitcher'
  import UserInformation from '@/components/UserInformation'
  import BackButton from '@/components/BackButton'
  import Login from '@/components/Login'
  import auth from './mage2rest.js';

  export default {
    data() {
      return {
        isAuthenticated: false,
      }
    },
    beforeMount() {
      this.isAuthenticated = auth.isAuthenticated()
    },
    name: 'app',
    components: {
      LanguageSwitcher,
      UserInformation,
      Login,
      BackButton
    },
    watch:{
      $route (to){
        if(to.name === '/'){
          this.isAuthenticated = auth.isAuthenticated()
        }
      }
    },
  }

</script>
