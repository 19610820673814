<template>
  <div class="contact-us full-screen">
    404 - Error Page
  </div>
</template>

<script>
  export default {}

</script>
