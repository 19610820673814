let config = {
    shop_url: 'https://webservice.voss-fluid.net/',
    api_base_url: 'https://webservice.voss-fluid.net/rest/DE_DE/V1/'
}

config.links = {
    store: {
        privacypolicy: config.shop_url + 'proline-global-intermediatepage/',
        imprint: config.shop_url + 'imprint/',
        downloadCenter: config.shop_url + 'prolinedownloadcenter/overview/'
    }
}

config.routes = {
    magento: {
        adminLogin: config.api_base_url + 'integration/admin/token/',
        customerToken: config.api_base_url + 'integration/customer/token/',
        customerInfos: config.shop_url + 'rest/V1/customers/me'
    },
    proline: {
        companies: config.api_base_url + 'proline/companies',
        companyAddress: config.api_base_url + 'proline/companyaddress',
        companyContact: config.api_base_url + 'proline/companycontact',
    },
    voss: {
        boxEmptyScan: config.api_base_url + 'voss/boxes/bulk/emtpy',
        boxInfo: config.api_base_url + 'voss/boxes',
        productInfo: config.api_base_url + 'voss/product',
        deliveryPoint: config.api_base_url + 'voss/boxes/locations',
    }
}

export default config