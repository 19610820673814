import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/sass/proline.main.scss'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-loading-overlay/dist/vue-loading.css';
import BootstrapVue from 'bootstrap-vue'
import VModal from 'vue-js-modal'
import Loading from 'vue-loading-overlay';
import { i18n } from '@/plugins/i18n'
import { Trans } from './plugins/Translation'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faClipboardList } from '@fortawesome/free-solid-svg-icons'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { faBarcode } from '@fortawesome/free-solid-svg-icons'
import { faAlignJustify } from '@fortawesome/free-solid-svg-icons'
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faInbox } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faPhone)
library.add(faEnvelope)
library.add(faAlignJustify)
library.add(faTrashAlt)
library.add(faPaperPlane)
library.add(faBarcode)
library.add(faInfoCircle)
library.add(faArrowAltCircleLeft)
library.add(faInbox)
library.add(faClipboardList)
library.add(faPlus)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(VModal, { dialog: true })
Vue.use(Loading);

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

export default router
