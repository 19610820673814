<template>
    <div class="back-button" v-on:click="previous">
        <font-awesome-icon icon="arrow-alt-circle-left" size="2x"/>
    </div>
</template>
<script>
    import { EventBus } from '../event-bus.js'

    export default {
        name: 'BackButton',
        data() {
            return {
                backRoutes: [
                    {route:"list" , backroute:"menu"},
                    {route:"infocenter" , backroute:"menu"},
                    {route:"storageinfo" , backroute:"infocenter"},
                    {route:"productinfo" , backroute:"infocenter"},
                    {route:"boxinfo" , backroute:"infocenter"},
                    {route:"menu" , backroute:"/"},
                    {route:"supportmenu" , backroute:"menu"},
                ],
            }
        },
        watch:{
            $route (to){
                if(to.name === 'home' || to.path === '/'){
                    document.querySelector('.back-button').setAttribute("style", "display: none;");
                }else {
                    document.getElementsByClassName('back-button')[0].setAttribute("style", "display: block;");
                }
            }
        },
        methods: {
            previous() {
                if (this.$router.currentRoute.name == 'list') {
                    let items = JSON.parse(localStorage.getItem('item_list'))
                    if (items !== null && items.length > 0) {

                        this.$modal.show('dialog', {
                            text: "<div class='clearitems-modal'>" +
                                    "<span>"+ this.$t('Should the already scanned data be deleted?') +"</span>" +
                                  "</div>",
                            buttons: [{
                                    title: this.$t("Yes"),
                                    handler: () => {
                                        this.clearItemlist()
                                        this.goBack()
                                    }},
                                {
                                    title: this.$t("No"),
                                    handler: () => {
                                        this.goBack()
                                    }}]
                        })
                    } else {
                        this.goBack()
                    }
                } else {
                    this.goBack();
                }
            },
            clearItemlist() {
                localStorage.setItem('item_list', '[]');
                EventBus.$emit('scanCountChanged', 0);
            },
            goBack() {
                this.$modal.hide('dialog')
                let currentRoute = this.$router.currentRoute;
                let item = this.backRoutes.find(item => item.route === currentRoute.name);
                this.$router.push(item.backroute);
            }
        },
        mounted() {
            document.querySelector('.back-button').setAttribute("style", "display: none;");
        }
    }
</script>