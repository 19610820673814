import axios from 'axios'
import config from './config';
import secureLS from 'secure-ls';
import moment from 'moment';

export default {
    authData: {
        username: '',
        password: '',
        token: '',
        errorCnt: 0
    },
    async doLogin(username, password){

        if(this.errorCnt >= 5){
            return false;
        }

        this.authData.username = username;
        this.authData.password = password;

        let token = await this.getBearerToken();
        if(token){
            this.authData.token = token;
            return await this.getUserInformation();
        }else {
            return false;
        }
    },
    async getBearerToken () {
        return new Promise((resolve) => {
            axios({
                method: 'post',
                url: config.routes.magento.customerToken,
                data: {
                    username: this.authData.username,
                    password: this.authData.password
                }
            }).then(response => {
                if (response.status === 200) {
                    let now = moment()
                    localStorage.setItem('login_token', response.data);
                    localStorage.setItem('login_expire_timestamp', now.add(55, 'minutes').unix().toString());
                    localStorage.setItem('login_user', ''+this.authData.username);
                    let ls = new secureLS()
                    ls.set('login_pass', this.authData.password)
                    this.errorCnt = 0;
                    resolve(response.data)
                }else {
                    resolve(false)
                }
            }).catch(e => {
                // eslint-disable-next-line no-console
                console.log(e)
                resolve(false)
            });
        });
    },
    async getUserInformation() {
        let company = await this.getProlineCompany();
        if(company){
            const [gotContact, gotCustomerInfo] = await Promise.all([
                this.getProlineCompanyContact(),
                this.getCustomerInfos()
            ]);
            return (gotContact && gotCustomerInfo)
        }else {
            return false;
        }
    },
    async getAuthHeader () {
        let expireTimestamp = localStorage.getItem('login_expire_timestamp');
        if(moment().unix() < expireTimestamp){
            let token = localStorage.getItem('login_token');
            return {'Content-Type': 'application/json', Authorization: 'Bearer ' + token}
        }else {
            this.errorCnt++;
            let sls = new secureLS()
            let username = localStorage.getItem('login_user')
            let password = sls.get('login_pass')
            let loggedIn = await this.doLogin(username, password)
            if(loggedIn){
                let token = localStorage.getItem('login_token');
                return {'Content-Type': 'application/json', Authorization: 'Bearer ' + token}
            }
        }
    },
    async getProlineCompany(){
        let auth = await this.getAuthHeader();
        return new Promise((resolve) => {
            axios({
                method: 'get',
                url: config.routes.proline.companies,
                headers: auth
            }).then(response => {
                if (response.status === 200) {
                    localStorage.setItem('proline_company', JSON.stringify(response.data.items));
                    resolve(true)
                }
            }).catch(e => {
                if(e){
                    // eslint-disable-next-line no-console
                    console.log(e)
                    resolve(false)
                }
            });
        });
    },
    async getProlineCompanyContact(){
        let auth = await this.getAuthHeader();
        return new Promise((resolve) => {
            let companyData = JSON.parse(localStorage.getItem('proline_company'));
            if(companyData.length > 0){
                axios({
                    method: 'get',
                    url: config.routes.proline.companyContact,
                    headers: auth,
                    params: {
                        companyId: companyData[0].company_id
                    }
                }).then(response => {
                    if (response.status === 200) {
                        localStorage.setItem('proline_company_contact', JSON.stringify(response.data.items));
                        resolve(true)
                    }
                }).catch(e => {
                    if(e){
                        // eslint-disable-next-line no-console
                        console.log(e)
                        resolve(false)
                    }
                });
            }else {
                resolve(false)
            }
        });
    },
    async getCustomerInfos(){
        let auth = await this.getAuthHeader();
        return new Promise((resolve) => {
            axios({
                method: 'get',
                url: config.routes.magento.customerInfos,
                headers: auth
            }).then(response => {
                if (response.status === 200) {
                    localStorage.setItem('mage_customer_infos', JSON.stringify(response.data));
                    resolve(true)
                }
            }).catch(e => {
                // eslint-disable-next-line no-console
                console.log(e)
                resolve(false)
            });
        });
    },
    async setEmptyScanBoxes(itemList){
        let auth = await this.getAuthHeader();
        return new Promise((resolve) => {
            axios({
                method: 'post',
                url: config.routes.voss.boxEmptyScan,
                headers: auth,
                data: {items: itemList}
            }).then(response => {
                resolve(response);
            }).catch(e => {
                resolve(e);
            });
        });
    },
    isAuthenticated(){
        if(localStorage.getItem('login_token')){
            return true;
        }else {
            return false;
        }
    },
}